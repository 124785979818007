<template>
  <div class="row mx-0 justify-content-center text-center p-3 position-relative">
    <div class="col-12 green-text-dark font12 bold mb-1">
      DONATION FREQUENCY
    </div>
    <div class="col-12 green-text-dark">
      {{frequency}}
    </div>
    <div class="col-12 font12" >
      <span v-if="recurringDonationType === 'DEBIT_ORDER'">{{displayDay}} of Every Month - </span>Starting {{dateDisplay}}
    </div>
    <div class="onhover" :class="{show: isHover === true }" @mouseover="isHover = true" @mouseleave="isHover = false">
      <div class="row m-0 h-100 justify-content-center align-items-center">
        <div class="col-auto">
          <Button color="darkgrey" size="small" btnText="EDIT" icon="arrow" @buttonClicked="$emit('goToPaymentPage')">
            <IconEdit size="size16" />
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
import { DateTime } from 'luxon'

export default {
  name: 'Review Donation Payment Summary',
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconEdit: defineAsyncComponent(() => import('@/components/icons/IconEdit.vue'))
  },
  props: ['frequency', 'startDate', 'recurringDonationType', 'debitOrderDay'],
  emits: ['goToPaymentPage'],
  data () {
    return {
      isHover: false,
      debitDayOptions: [
        {
          day: 1,
          description: '1st'
        },
        {
          day: 3,
          description: '3rd'
        },
        {
          day: 15,
          description: '15th'
        },
        {
          day: 25,
          description: '25th'
        }
      ]
    }
  },
  computed: {
    dateDisplay () {
      return DateTime.fromMillis(this.startDate).toFormat('dd LLLL yyyy')
    },
    displayDay () {
      const theDayOption = this.debitDayOptions.filter(day => {
        if (day.day !== this.debitOrderDay) {
          return false
        }
        return true
      })
      return theDayOption[0].description
    }
  }
}
</script>
<style scoped>
.onhover {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 0;
}
.onhover.show {
  opacity: 1;
}
</style>
